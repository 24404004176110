export const projects = [
  {
    id: 1,
    title: "Portfolio",
    description: "My personal portfolio website",
    stack: "React, Tailwind, Styled Components",
    website: "https://mmbattaloglu.com",
  },
  {
    id: 2,
    title: "Turkyap",
    description: "Website for a real estate company",
    stack: "HTML, CSS, Bootstrap, JavaScript, PHP, MySQL",
    website: "https://turkyap.com.tr",
  },
];

export const education = [
  {
    id: 2,
    title: "MSc, Game Technologies",
    description: "Gazi University",
    date: "2024-Currently",
  },
  {
    id: 1,
    title: "BSc, Computer Engineering",
    description: "Mugla Sitki Kocman University",
    date: "2018-2023",
  },
];

export const experiences = [
  {
    id: 4,
    title: "Junior Game Developer",
    company: "Playable Factory",
    date: "04/2024-Currently",
  },
  {
    id: 3,
    title: "Frontend Developer Trainee",
    company: "Devexperts",
    date: "10/2023-02/2024",
  },
  {
    id: 2,
    title: "Mobile Application Developer Intern",
    company: "Kalem Software",
    date: "07/2022-08/2022",
  },
  {
    id: 1,
    title: "Game Developer Intern",
    company: "Grimanda Games",
    date: "06/2021-09/2021",
  },
];
