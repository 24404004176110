import styled from "styled-components";

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.3rem;
  margin-top: 1rem;

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    font-size: 0.7rem;
    color: var(--color-tertiary);

    @media screen and (max-width: 1200px) {
      font-size: 0.6rem;
    }
  }

  span {
    font-size: 0.8rem;
    color: var(--color-grey-700);

    @media screen and (max-width: 1200px) {
      font-size: 0.7rem;
    }
  }
`;

export default InfoItem;
