import Box from "../../ui/box/box.ui";
import SidebarItem from "./sidebar-item.component";
import { FaRegFileAlt, FaRegUser } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";
import { TiContacts } from "react-icons/ti";

const Sidebar = () => {
  return (
    <Box as={"nav"}>
      <SidebarItem to={"/about"}>
        <FaRegUser />
        <span>About</span>
      </SidebarItem>

      <SidebarItem to={"/resume"}>
        <FaRegFileAlt />
        <span>Resume</span>
      </SidebarItem>

      <SidebarItem to={"/projects"}>
        <MdOutlineWork />
        <span>Projects</span>
      </SidebarItem>

      <SidebarItem to={"/contact"}>
        <TiContacts />
        <span>Contact</span>
      </SidebarItem>
    </Box>
  );
};

export default Sidebar;
