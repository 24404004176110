import styled from "styled-components";
import TertiaryTitle from "../../ui/titles/tertiary-title.ui";

const SkillsLayout = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: auto auto;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 2rem 70vw;
    grid-template-rows: auto auto;
    gap: 0.5rem;
  }
`;

const StyledSkillsIcon = styled.div`
  svg {
    font-size: 2rem;
    color: var(--color-primary);
  }
`;

const StyledSkillsText = styled.p`
  font-size: 0.85rem;
  margin-top: 0.25rem;
  grid-column: 2 / 3;

  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
    grid-column: 1 / 3;
  }
`;

const SkillsItem = ({ icon, title, text }) => {
  return (
    <SkillsLayout>
      <StyledSkillsIcon>{icon}</StyledSkillsIcon>
      <TertiaryTitle>{title}</TertiaryTitle>
      <StyledSkillsText>{text}</StyledSkillsText>
    </SkillsLayout>
  );
};

export default SkillsItem;
