import PrimaryTitle from "../../ui/titles/primary-title.ui";
import SecondaryTitle from "../../ui/titles/secondary-title.ui";
import Skills from "./skills.component";

const About = () => {
  return (
    <section about="about">
      <PrimaryTitle>About</PrimaryTitle>
      <p className="mt-5 text-sm sm:text-md">
        Hello, I'm Mustafa, a junior software developer. With a strong
        foundation in software development principles and a keen interest in
        learning, I am dedicated to continuously improving my skills and
        exploring new technologies. Driven by curiosity and a growth mindset, I
        embrace challenges as opportunities for growth and am always eager to
        learn from experiences and feedback. I believe in the power of teamwork
        and effective communication to drive success in any project. As a
        software developer, I am committed to staying updated with industry
        trends and best practices, allowing me to adapt and evolve in a rapidly
        changing technological landscape. I am excited about the possibilities
        that software development offers and am eager to contribute my skills
        and enthusiasm to meaningful projects.
      </p>
      <SecondaryTitle margin={"my-5"}>What can I do?</SecondaryTitle>
      <Skills />
    </section>
  );
};

export default About;
