import { education, experiences } from "../../data/data";
import List from "../../ui/list/list.ui";
import PrimaryTitle from "../../ui/titles/primary-title.ui";
import SecondaryTitle from "../../ui/titles/secondary-title.ui";
import TertiaryTitle from "../../ui/titles/tertiary-title.ui";

const Resume = () => {
  return (
    <section about="resume">
      <PrimaryTitle>Resume</PrimaryTitle>
      <div className="p-3">
        <SecondaryTitle margin="my-3">Education</SecondaryTitle>
        <List bullet={true}>
          {education.map((item) => (
            <li key={item.id}>
              <TertiaryTitle>{item.title}</TertiaryTitle>
              <p className="text-sm sm:text-md">{item.description}</p>
              <p className="text-xs sm:text-sm text-gray-400">{item.date}</p>
            </li>
          ))}
        </List>
        <SecondaryTitle margin="my-3">Experiences</SecondaryTitle>
        <List bullet={true}>
          {experiences.map((item) => (
            <li key={item.id}>
              <TertiaryTitle>{item.title}</TertiaryTitle>
              <p>{item.company}</p>
              <p className="text-sm text-gray-400">{item.date}</p>
            </li>
          ))}
        </List>
      </div>
    </section>
  );
};

export default Resume;
