import { FaLinkedinIn } from "react-icons/fa6";
import PrimaryTitle from "../../ui/titles/primary-title.ui";
import { MdEmail } from "react-icons/md";
import List from "../../ui/list/list.ui";

const Contact = () => {
  return (
    <div>
      <section about="contact">
        <PrimaryTitle>Contact</PrimaryTitle>
        <List bullet={false}>
          <li>
            <a
              href="mailto:mmbattaloglu@gmail.com"
              className="flex items-center gap-3"
            >
              <MdEmail />
              <span className="text-sm sm:text-md">
                You can send me an email
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://linkedin.com/in/mbattaloglu"
              target="_blank"
              rel="noreferrer noopener"
              className=" flex items-center gap-3"
            >
              <FaLinkedinIn className="text-blue-700" />
              <span className="text-sm sm:text-md">
                Connect with me on LinkedIn
              </span>
            </a>
          </li>
        </List>
      </section>
    </div>
  );
};

export default Contact;
