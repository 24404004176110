import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import InfoItem from "./info-item.component";
import styled from "styled-components";

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
`;

const Infos = () => {
  return (
    <div>
      <InfoItem>
        <InfoText>
          <h4>
            <FaLocationDot />
            Location
          </h4>
          <span>Istanbul, Turkey</span>
        </InfoText>
      </InfoItem>
      <InfoItem>
        <InfoText as={"a"} href="mailto:mmbattaloglu@gmail.com">
          <h4>
            <MdEmail />
            Email
          </h4>
          <span>mmbattaloglu@gmail.com</span>
        </InfoText>
      </InfoItem>
    </div>
  );
};

export default Infos;
