import styled from "styled-components";
import SkillsItem from "./skills-item.component";
import { FaDesktop, FaMobileScreen, FaGamepad } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";

const SkillsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  gap: 1rem;
`;

const Skills = () => {
  return (
    <SkillsLayout>
      <SkillsItem
        icon={<FaGamepad />}
        title={"Games"}
        text={
          "I'm mainly working as a HTML5 game developer. While I'm still learning new technologies/engines, I'm focused on using simple tools to create fun and engaging games. I'm dedicated to continually expanding my knowledge to create better gaming experiences."
        }
      />
      <SkillsItem
        icon={<FaDesktop />}
        title={"Web Applications"}
        text={
          "I create responsive websites with modern tools like React. Each site I build is top-notch, featuring user-friendly designs and innovative features. I stay updated on the latest trends to ensure websites look great and function seamlessly."
        }
      />
      <SkillsItem
        icon={<FaMobileScreen />}
        title={"Mobile Applications"}
        text={
          "I'm experienced in building mobile apps using React Native, which means the apps work on both iOS and Android devices. By using React Native, I can create apps that look and feel great on any platform, saving time and effort for development."
        }
      />
      <SkillsItem
        icon={<GrServices />}
        title={"API's and Services"}
        text={
          "I'm just starting to learn backend development basics. I use simple tools to create reliable systems. My goal is to keep things clear and easy to understand. I'm always learning more to make sure I can help clients with their projects."
        }
      />
    </SkillsLayout>
  );
};

export default Skills;
