import Box from "../../ui/box/box.ui";
import Infos from "./infos.component";
import Links from "./links.component";

const Infobar = () => {
  return (
    <Box as={"aside"} center>
      <h2
        className="
        text-sm
        lg:text-lg
        font-semibold
        text-center
        text-gray-900
        "
      >
        Mustafa Battaloglu
      </h2>
      <p className="text-xs lg:text-sm text-gray-500">Software Developer</p>
      <Links />
      <Infos />
    </Box>
  );
};

export default Infobar;
