import Infobar from "../components/infobar/infobar.component";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar.component";
import Box from "../ui/box/box.ui";

const AppLayout = () => {
  return (
    <>
      <Infobar />
      <Box as={"main"}>
        <Outlet />
      </Box>
      <Sidebar />
    </>
  );
};

export default AppLayout;
