import { Routes, Route, Navigate } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import AppLayout from "./app.layout";
import About from "../pages/about/about.page";
import Contact from "../pages/contact/contact.page";
import Projects from "../pages/projects/projects.page";
import Resume from "../pages/resume/resume.page";
import NotFound from "../pages/not-found/not-found.page";

const App = () => {
  return (
    <>
      <Routes>
        <Route element={<AppLayout />}>
          <Route index element={<Navigate replace to="/about" />} />
          <Route path="/about" element={<About />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Analytics />
    </>
  );
};

export default App;
