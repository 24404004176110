import styled from "styled-components";
import { FaItchIo, FaGithub, FaLinkedinIn } from "react-icons/fa6";

const LinkLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const Links = () => {
  return (
    <LinkLayout>
      <a
        href="https://github.com/mbattaloglu"
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-900 hover:text-gray-200 transition-colors duration-300  p-2 rounded-md hover:bg-gray-900"
      >
        <FaGithub />
      </a>
      <a
        href="https://linkedin.com/in/mbattaloglu"
        target="_blank"
        rel="noreferrer noopener"
        className="text-blue-700 hover:text-gray-200 transition-colors duration-300  p-2 rounded-md hover:bg-blue-700"
      >
        <FaLinkedinIn />
      </a>
      <a
        href="https://mbattaloglu.itch.io"
        target="_blank"
        rel="noreferrer noopener"
        className="text-pink-500 hover:text-gray-200 transition-colors duration-300  p-2 rounded-md hover:bg-pink-500"
      >
        <FaItchIo />
      </a>
    </LinkLayout>
  );
};

export default Links;
