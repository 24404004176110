const SecondaryTitle = ({ children, margin }) => {
  return (
    <h2
      className={`text-xl sm:text-2xl font-semibold font-serif tracking-wider ${margin}`}
    >
      {children}
    </h2>
  );
};

export default SecondaryTitle;
