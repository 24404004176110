import { projects } from "../../data/data";
import List from "../../ui/list/list.ui";
import PrimaryTitle from "../../ui/titles/primary-title.ui";
import TertiaryTitle from "../../ui/titles/tertiary-title.ui";

const Projects = () => {
  return (
    <section about="projects">
      <PrimaryTitle>Projects</PrimaryTitle>
      <List bullet={true}>
        {projects.map((project) => (
          <li key={project.id}>
            <TertiaryTitle>{project.title}</TertiaryTitle>
            <p className="text-xs sm:text-sm">{project.description}</p>
            <p className="text-xs sm:text-sm text-gray-400">
              <i>Used: </i>
              {project.stack}
            </p>
            <a
              className="text-xs sm:text-sm text-gray-400"
              href={project.website}
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit Website {project.id === 1 && "(this website)"}
            </a>
          </li>
        ))}
      </List>
    </section>
  );
};

export default Projects;
