import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-1">
      <h1 className="text-md sm:text-lg font-semibold">Not Found</h1>
      <p>The page you are looking for is not found.</p>
      <Link to="/about" className="text-blue-500 hover:underline">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
