import { NavLink } from "react-router-dom";
import styled from "styled-components";

const SidebarItem = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: var(--color-grey-600);
    padding: 1rem;
    transition: all 0.3s;
    border-radius: 1rem;
    width: 100%;

    @media screen and (max-width: 768px) {
      justify-content: center;
      padding: 0.5rem;
    }
  }

  & span {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &:hover,
  &:active,
  &.active:link,
  &.active:visited {
    color: var(--color-grey-0);
    background-color: var(--color-primary);
    border-radius: 1rem;
  }

  & svg {
    width: 2rem;
    height: 2rem;
    color: var(--color-grey-400);
    transition: all 0.3s;
  }

  &:hover svg,
  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: var(--color-grey-0);
  }
`;

export default SidebarItem;
