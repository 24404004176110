import styled from "styled-components";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

  ${(props) =>
    props.center &&
    `
    justify-content: center;
    align-items: center;
  `}

  ${(props) =>
    props.as === "main" &&
    `
    padding: 2rem;
    max-height: 85dvh;
    overflow-y: auto ;
    overflow-x: hidden;

    @media screen and (max-width: 1000px) {
      max-height: 47dvh;
    }
  `}

  ${(props) =>
    props.as === "nav" &&
    `
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media screen and (max-width: 1000px) { 
      flex-direction: row;
      grid-row: 2 / 3;
    }
  `}
`;

export default Box;
